import * as React from "react"
import cx from "classnames"
import { graphql } from "gatsby"
import marked from "marked"

import { Layout, Breadcrumb, Certificates, Seo } from "../components"
import { isMobile } from "react-device-detect"

const AboutPage = ({ data }) => {
  const { frontmatter: content } = data.content

  return (
    <Layout>
      <Seo title="O mnie" />

      <div className="active-dark">
        <Breadcrumb
          title="O mnie"
          background={content.background}
          backgroundMobile={content.backgroundMobile}
        />

        <div className="about about-area bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35">
                {!isMobile && (
                  <div className="col-lg-5 col-md-12 order-md-1 order-2">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src={content.image}
                        alt="About Images"
                      />
                    </div>
                  </div>
                )}

                <div className="col-lg-7 col-md-12 order-md-2 order-1">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{content.title}</h2>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: marked(content.content),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AdditionalContent
          title={content.left.title}
          content={content.left.content}
          image={isMobile ? content.image : content.left.image}
          imageSide="right"
        />
        <AdditionalContent
          title={content.right.title}
          content={content.right.content}
          image={content.right.image}
          imageSide="left"
        />

        <div className="about__certificates portfolio-area">
          <h2 className="portfolio-area__title title">
            {content.certificates.title}
          </h2>
          <div className="container">
            <div className="portfolio-sacousel-inner mb--55">
              <Certificates certificates={content.certificates.list} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

type AdditionalContentProps = {
  title: string
  content: string
  image: string
  imageSide: "left" | "right"
}

const AdditionalContent = ({
  title,
  content,
  image,
  imageSide,
}: AdditionalContentProps) => (
  <div
    className={cx("rn-service-details about__additional-section", {
      "bg_color--1": imageSide === "left",
    })}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="service-details-inner">
            <div className="inner">
              <div className="row sercice-details-content align-items-center">
                <div
                  className={cx("col-lg-6 col-12 order-md-1", {
                    "order-2": imageSide === "right",
                  })}
                >
                  {imageSide === "left" ? (
                    <div className="thumb">
                      <img className="w-100" src={image} alt="Service Images" />
                    </div>
                  ) : (
                    <div className="details mt_md--30 mt_sm--30">
                      <h4 className="title">{title}</h4>
                      <div
                        dangerouslySetInnerHTML={{ __html: marked(content) }}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={cx("col-lg-6 col-12 order-md-2", {
                    "order-1": imageSide === "right",
                  })}
                >
                  {imageSide === "left" ? (
                    <div className="details mt_md--30 mt_sm--30">
                      <h4 className="title">{title}</h4>
                      <div
                        dangerouslySetInnerHTML={{ __html: marked(content) }}
                      />
                    </div>
                  ) : (
                    <div className="thumb">
                      <img className="w-100" src={image} alt="Service Images" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export const guery = graphql`
  {
    content: markdownRemark(
      fields: { collection: { eq: "pages" }, slug: { eq: "about" } }
    ) {
      frontmatter {
        background
        backgroundMobile
        image
        title
        content
        left {
          image
          title
          content
        }
        right {
          image
          title
          content
        }
        certificates {
          title
          list {
            image
          }
        }
      }
    }
  }
`

export default AboutPage
